import * as React from 'react';
import { ContainerQuery } from 'react-container-query';
import styledComponents, {
  ThemedStyledInterface,
  ThemeProvider as StyledComponentThemeProvider,
} from 'styled-components';

const size = {
  small: 575,
  mobile: 576,
  tablet: 768,
  laptop: 1366,
  desktop: 1920,
};

const deviceBreakpoints = {
  small: `max-width: ${size.small}px`,
  mobile: `min-width: ${size.mobile}px`,
  tablet: `min-width: ${size.tablet}px`,
  laptop: `min-width: ${size.laptop}px`,
  desktop: `min-width: ${size.desktop}px`,
};

const mixins = {
  unselectable: `
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;`,
};

const colors = {
  primary: '#9C7346',
  primaryBg: '#262A36',

  secondary: '#9ea8ba',
  inputText: '#444',
  label: '#444',
  placeholder: '#b4b4b4',
  placeholderFocused: '#e2e2e2',

  white: '#FFFFFF',
  black: '#000000',

};

export type QueryBreakpointsContextShape = {
  small: boolean;
  mobile: boolean;
  tablet: boolean;
  laptop: boolean;
  desktop: boolean;
};

const initialQueryBreakpointsContext: QueryBreakpointsContextShape = {
  small: false,
  mobile: false,
  tablet: false,
  laptop: false,
  desktop: false,
};

export const theme = {
  size,
  deviceBreakpoints,
  mixins,
  breakpoints: ['576px', '768px', '992px', '1200px'],
  space: [0, '1rem', '2rem', '3rem', '4rem'],
  colors: colors,
  fonts: {
    variant1: 'Quicksand-Medium',
    merriweather: 'Merriweather-Regular',
    lato: 'Lato-Regular',
  },
  queryBreakpoints: initialQueryBreakpointsContext,
  currentBreakpoint: 'small',
};

export type Theme = typeof theme;

type ThemeProviderRenderProps = {
  render: () => React.ReactNode;
};

const queryBreakpoints = {
  small: { maxWidth: size.small },
  mobile: { minWidth: size.mobile, maxWidth: size.tablet - 1 },
  tablet: { minWidth: size.tablet, maxWidth: size.laptop - 1 },
  laptop: { minWidth: size.laptop, maxWidth: size.desktop - 1 },
  desktop: { minWidth: size.desktop },
};

export const ThemeProvider = ({ render }: ThemeProviderRenderProps) => {
  return (
    <ContainerQuery query={queryBreakpoints}>
      {({ small, mobile, tablet, laptop, desktop }) => (
        <StyledComponentThemeProvider
          theme={{
            ...theme,
            colors: colors,
            queryBreakpoints: { small, mobile, tablet, laptop, desktop },
          }}
        >
          {render()}
        </StyledComponentThemeProvider>
      )}
    </ContainerQuery>
  );
};

export const styled: ThemedStyledInterface<Theme> = styledComponents;
