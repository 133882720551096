import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { styled, ThemeProvider } from './ThemeProvider';

const { Helmet } = require('react-helmet');
const logo = require('./logo-JB-PNG.png');

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;;
  }

  * {
    box-sizing: border-box;
  }
`;
const Heading = styled.h1`
  font-size: 16px;
  letter-spacing: .02em;
  line-height: 34px;
  font-weight: 700;
  font-style: normal;
  color: #222;
  text-transform: uppercase;
`;

const Container = styled.div`
  padding: 1em .5em 2em .5em;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  max-width: 700px;
  @media (${props => props.theme.deviceBreakpoints.mobile}) {
    padding: 1em 2em 2em 2em;
  }

  @media (${props => props.theme.deviceBreakpoints.tablet}) {
    padding: 1em 4em 2em 4em;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: .8em;

  a {
    color: ${({ theme }) => theme.colors.primary};
    margin: 1em;
    text-transform: lowercase;
    text-decoration: none;
  }
`;

type ImageSharpFixed = {
  aspectRatio: number;
  base64: string;
  height: number;
  originalName: string;
  src: string;
  srcSet: string;
  srcSetWebp: string;
  srcWebp: string;
  tracedSVG: string;
  width: number;
};

type ImagesQueryResult = {
  file: { childImageSharp: { fixed: ImageSharpFixed } }
};

export const Layout: React.FC<{ title?: string; }> = ({ children, title }) => {
  return (
    <ThemeProvider
      render={() => (
        <Container>
          <Helmet>
            <link rel="stylesheet"
                  href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap"
            />
          </Helmet>
          <GlobalStyle/>
          <img
            width="200"
            src={logo}
            alt="JB Solutions logo"
          />
          {title && (
            <Heading>
              {title}
            </Heading>
          )}
          {children}
          <Footer
            css={`
              margin-top: auto;
            `}
          >
            <a href="/konsultacje-ksiegowe">Konsultacje księgowe</a>
            <span>•</span>
            <a href="/konsultacje-podatkowe-prawne">Konsultacje podatkowe/prawne</a>
            <span>•</span>
            <a href="/szkolenie">Szkolenia</a>
          </Footer>
          <Footer>
            <a href="https://jbsolutions.pl/regulamin">Regulamin</a>
            <span>•</span>
            <a href="https://jbsolutions.pl/polityka-prywatnosci">Polityka Prywatności</a>
          </Footer>
        </Container>
      )}
    />
  );
};
